// JS for the personal details page. Just binds Anonymous switching stuff for the form.

import Form from "Scripts/common/form";
import ImageUploadCrop from 'Scripts/common/image-upload-crop';

export default class PersonalDetails {
	constructor() {
		$('#anonymous').on('change', this.onToggleAnonymous.bind(this));

		this.form = $("#form-personal-details");

		if(this.form.length) {
			this.image = new ImageUploadCrop({
				width:     500,
				height:    500,
				restrict:  true,
				container: $('.js-image-container'),
				modal:     $("#js-modal-image-crop"),
			});

			const endpoint = this.form.attr('action');

			new Form({
				form:      this.form,
				endpoint:  endpoint + (endpoint.includes('ajax') ? '' : '/ajax'), // This is so that the form works should JS fail for whatever reason
				addToForm: this.onSubmitForm.bind(this),
				callback:  this.onSuccessfulFormSubmit.bind(this),
			});
		}
	}

	onToggleAnonymous() {
		const newName = $('#anonymous').is(':checked') ? "Anonymous" : $.trim($('#user\\.firstName').val() + ' ' + $('#user\\.lastName').val());
		console.log("PersonalDetails.onToggleAnonymous", newName);
		$('#user\\.nickname').val(newName);
	}

	onSubmitForm(formData) {
		this.image.addImagesToFormData(formData, "images");
		return formData;
	}

	onSuccessfulFormSubmit(response) {
		const modal = this.form.parents('.modal');

		if(modal.length) {
			modal.modal('hide');
		} else {
			window.location.href = '/dashboard';
		}
	}
};
