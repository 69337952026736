// Fill an input with text from a button
// Usage:
// new Suggestion(element/elements)
// <button type="button" data-target="#input-selector" data-value="Text, number etc">

export default class Suggestion {
	constructor(inputEl, callback) {
		console.log("Suggestion.constructor")
		if(!inputEl.length) return;

		this.el = $(inputEl);
		this.el.on('click', this.onClick.bind(this));
		this.callback = callback || false;

		// Do we want to toggle the suggestions' visibility based on the target?
		this.el.each((i, el) => {
			el = $(el);
			let toggleContainer = $(el.data('toggleContainer'));

			if(toggleContainer.length) {
				$($(el).data('target')).on('keyup', this.onToggleContainer.bind(this, toggleContainer));
			}
		});
	}

	onClick(e) {
		console.log("Suggestion.onClick");

		let el = $(e.currentTarget);
		let target = $(el.data('target'));
		let value = el.data('value');

		if(target[0].tagName == "INPUT" || target[0].tagName == "TEXTAREA") {
			target.val(value);
		} else {
			target.html(value);
		}

		// If the input auto-expands (using text-area-expand.js), then this triggers the resize
		target.trigger("input");

		// If the parent class needs to run anything extra, do that now
		if(this.callback) {
			this.callback(e);
		}
	}

	onToggleContainer(toggleContainer, e) {
		let input = $(e.currentTarget);
		toggleContainer.collapse(input.val() == "" ? "show" : "hide");
	}
}
